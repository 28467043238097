import React, { useEffect, useState } from "react";
import API from "../services";
import Show from "../models/Show";
import getSymbolFromCurrency from "../helpers/getSymbolFromCurrency";
import { ShowProject } from "./edit-show-projects.ctx";
import Multiplex from "../models/Multiplex";
import {MultiplexDashboard} from "./edit-multiplex-dashboards.ctx";

const AppContext = React.createContext({
  isLoading: true,
  allShows: [] as Show[],
  allShowsListeners: [] as Function[],
  oneShowListeners: [] as Function[],
  currentShow: {} as Show,
  loadCurrentShow: (showId: string) => {},
  currentShowProjects: [] as ShowProject[],
  increaseShowsFetchLimit: () => {},
  resetContext: () => {},
  notificationIsOn: false,
  toggleNotification: (notificationSetup: {
    type: "success" | "error";
    content: string;
  }) => {},
  notificationSetup: {} as { type: "success" | "error"; content: string },
  currentShowCurrencySymbol: "€",
  deleteCurrentShow: () => {},
  allMultiplexes: [] as Multiplex[],
  allMultiplexesListeners: [] as Function[],
  oneMultiplexListeners: [] as Function[],
  currentMultiplex: {} as Multiplex,
  loadCurrentMultiplex: (multiplexId: string) => {},
  currentMultiplexDashboards: [] as MultiplexDashboard[],
  deleteCurrentMultiplex: () => {},
  currentMultiplexCurrencySymbol: "€",
  increaseMultiplexesFetchLimit: () => {},
});

export const AppContextProvider: React.FC = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [allShows, setAllShows] = useState([] as Show[]);
  const [allShowsFetchLimit, setAllShowsFetchLimit] = useState(10);
  const [allShowsListeners, setAllShowsListeners] = useState([] as Function[]);
  const [oneShowListeners, setOneShowListeners] = useState([] as Function[]);
  const [currentShow, setCurrentShow] = useState({} as Show);
  const [currentShowCurrencySymbol, setCurrentShowCurrencySymbol] =
    useState("");
  const [currentShowProjects, setCurrentShowProjects] = useState(
    [] as ShowProject[]
  );
  const [currentShowProjectsListeners, setCurrentShowProjectsListeners] =
    useState([] as Function[]);
  const [notificationIsOn, setNotificationIsOn] = useState(false);
  const [notificationSetup, setNotificationSetup] = useState(
    {} as { type: "success" | "error"; content: string }
  );

  const [allMultiplexes, setAllMultiplexes] = useState([] as Multiplex[])
  const [allMultiplexesListeners, setAllMultiplexesListeners] = useState([] as Function[]);
  const [oneMultiplexListeners, setOneMultiplexListeners] = useState([] as Function[]);
  const [currentMultiplex, setCurrentMultiplex] = useState({} as Multiplex)
  const [currentMultiplexDashboards, setCurrentMultiplexDashboards] = useState(
    [] as MultiplexDashboard[]
  );
  const [currentMultiplexDashboardsListeners, setCurrentMultiplexDashboardsListeners] =
    useState([] as Function[]);
  const [currentMultiplexCurrencySymbol, setCurrentMultiplexCurrencySymbol] =
    useState("");
  const [allMultiplexesFetchLimit, setAllMultiplexesFetchLimit] = useState(10);

  const loadCurrentShow = (showId: string) => {
    if (currentShow.id === showId) {
      return;
    } else {
      setIsLoading(true);
      const oneShowListener: Function = API.listenToOneShow(
        showId,
        (show: Show) => {
          setCurrentShow(show);
          setCurrentShowCurrencySymbol(
            getSymbolFromCurrency(show.pledgesCurrency) || "€"
          );
          if ('multiplexId' in show && typeof show.multiplexId === 'string') {
            loadCurrentMultiplex(show.multiplexId)
          } else {
            setCurrentMultiplex({} as Multiplex)
          }
          setIsLoading(false);
        }
      );
      const currentShowProjectsListener: Function = API.listenToOneShowProjects(
        showId,
        (projects: ShowProject[]) => {
          setCurrentShowProjects(projects);
          setIsLoading(false);
        }
      );
      oneShowListeners.forEach((listener) => listener());
      currentShowProjectsListeners.forEach((listener) => listener());
      setOneShowListeners([oneShowListener]);
      setCurrentShowProjectsListeners([currentShowProjectsListener]);
    }
  };

  const loadCurrentMultiplex = (multiplexId: string) => {
    if (currentMultiplex.id === multiplexId) {
      return;
    } else {
      setIsLoading(true);
      const oneMultiplexListener: Function = API.listenToOneMultiplex(
        multiplexId,
        (multiplex: Multiplex) => {
          setCurrentMultiplex(multiplex);
          setIsLoading(false);
          setCurrentMultiplexCurrencySymbol(
            getSymbolFromCurrency(multiplex.pledgesCurrency) || "€"
          );
        }
      );
      const currentMultiplexDashboardsListener: Function = API.listenToOneMultiplexDashboards(
        multiplexId,
        (dashboards: MultiplexDashboard[]) => {
          setCurrentMultiplexDashboards(dashboards);
          setIsLoading(false);
        }
      );
      oneMultiplexListeners.forEach((listener) => listener());
      currentMultiplexDashboardsListeners.forEach((listener) => listener());
      setOneMultiplexListeners([oneMultiplexListener]);
      setCurrentMultiplexDashboardsListeners([currentMultiplexDashboardsListener]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const allShowsListener: Function = API.listenToAllShows((shows: Show[]) => {
      setAllShows(shows);
      setIsLoading(false);
    }, allShowsFetchLimit);
    const allMultiplexesListener: Function = API.listenToAllMultiplexes((multiplexes: any[]) => {
      setAllMultiplexes(multiplexes);
      setIsLoading(false);
    }, allMultiplexesFetchLimit);
    setAllShowsListeners([allShowsListener]);
    setAllMultiplexesListeners([allMultiplexesListener])
  }, [allShowsFetchLimit, allMultiplexesFetchLimit]);

  const resetContext = () => {
    setIsLoading(true);
  };

  const increaseShowsFetchLimit = () => {
    setAllShowsFetchLimit(allShowsFetchLimit + 10);
  };

  const toggleNotification = (notificationSetup: {
    type: "success" | "error";
    content: string;
  }) => {
    if (!notificationIsOn) {
      setNotificationSetup(notificationSetup);
      setNotificationIsOn(true);
      setTimeout(() => {
        setNotificationIsOn(false);
      }, 2000);
    }
  };

  const deleteCurrentShow = () => {
    const showToDelete = currentShow.id;
    API.deleteShow(showToDelete);
  };

  const deleteCurrentMultiplex = () => {
    const multiplexToDelete = currentMultiplex.id;
    API.deleteMultiplex(multiplexToDelete);
  };

  const increaseMultiplexesFetchLimit = () => {
    setAllMultiplexesFetchLimit(allMultiplexesFetchLimit + 10);
  };

  return (
    <AppContext.Provider
      value={{
        isLoading,
        allShows,
        allShowsListeners,
        oneShowListeners,
        currentShow,
        loadCurrentShow,
        currentShowProjects,
        increaseShowsFetchLimit,
        resetContext,
        notificationIsOn,
        toggleNotification,
        notificationSetup,
        currentShowCurrencySymbol,
        deleteCurrentShow,
        allMultiplexes,
        allMultiplexesListeners,
        oneMultiplexListeners,
        currentMultiplex,
        loadCurrentMultiplex,
        currentMultiplexDashboards,
        deleteCurrentMultiplex,
        currentMultiplexCurrencySymbol,
        increaseMultiplexesFetchLimit
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export default AppContext;
