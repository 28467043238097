import React, {useContext} from "react";
import AppContext from "../../../contexts/app.ctx";
import API from "../../../services";
import BaseCard from "../../Base/BaseCard/BaseCard";
import classNames from "classnames";
import BaseLabel from "../../Base/BaseLabel/BaseLabel";
import BaseIcon from "../../Base/BaseIcon/BaseIcon";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import "./MultiplexCurrentDashboardSelector.css"
import ClientUrlConfig from "../../../configs/dons-live-client.config";

interface ContainerProps {
  gridId: string;
}

const MultiplexCurrentDashboardSelector: React.FC<ContainerProps> = (props) => {
  const appCtx = useContext(AppContext);
  const baseUrl = ClientUrlConfig();

  const setCurrentDashboard = (dashboardId: string) => {
    let newDashboardId = "";
    if (dashboardId !== appCtx.currentMultiplex.currentDashboardId) {
      newDashboardId = dashboardId;
    }
    API.changeCurrentDashboardId(appCtx.currentMultiplex.id, newDashboardId);
  };

  return (
    <BaseCard
      id={props.gridId}
      className={classNames({
        "multiplex-current-dashboard-selector": true
      })}
      tight
    >
      <BaseLabel>Compteur mis en avant</BaseLabel>
      <div className="multiplex-current-dashboard__list">
        {appCtx.currentMultiplexDashboards.map((dashboard, index) => (
          <div
            key={dashboard.id}
            className={classNames({
              "multiplex-current-dashboard-selector__list__item": true,
              "multiplex-current-dashboard-selector__list__item--active":
                dashboard.id === appCtx.currentMultiplex.currentDashboardId
            })}
            onClick={() => setCurrentDashboard(dashboard.id)}
          >
            <a
              className="multiplex-current-dashboard-selector__list__item__logo"
              style={{
                backgroundImage: `url(${dashboard.logoUrl})`
              }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              href={`${baseUrl}${appCtx.currentShow.urlSlug}/multiplexsummary?dashboard=${dashboard.id}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="multiplex-current-dashboard-selector__list__item__logo__counter-link">
                <BaseIcon icon={faLink as IconProp} color="#848aae" />
              </div>
            </a>
            <div className="multiplex-current-dashboard-selector__list__item__infos">
              <div className="multiplex-current-dashboard-selector__list__item__infos__name">
                {dashboard.name}
              </div>
              <div className="multiplex-current-dashboard-selector__list__item__infos__goal">
                <span
                  className={classNames({
                    "multiplex-current-dashboard-selector__list__item__infos__goal--reached":
                      dashboard.totalPledgesAmount >= dashboard.goal
                  })}
                >
                  {(dashboard.totalPledgesAmount / 100).toLocaleString(
                      undefined,
                      { maximumFractionDigits: 0 }
                    ) +
                    " " +
                    appCtx.currentShowCurrencySymbol}
                </span>
                {" / "}
                <span>
                  {(dashboard.goal / 100).toLocaleString(undefined, {
                      maximumFractionDigits: 0
                    }) +
                    " " +
                    appCtx.currentShowCurrencySymbol}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BaseCard>
  );
};

export default MultiplexCurrentDashboardSelector;
