import React, {useContext, useEffect, useState} from "react";
import AppContext from "./app.ctx";
import Multiplex from "../models/Multiplex";
import API from "../services";
import { v4 as uuidv4 } from "uuid";

export type DashboardProject = {
  id: string
  name: string
  parentShowId: string
  place: string
  goal: number
  dashboardId?: string
}

export type MultiplexDashboard = {
  id: string
  name: string
  logoUrl: string
  goal: number
  totalPledgesAmount: number
  selectedProjects: DashboardProject[],
  position: number
}

const EditMultiplexDashboardsContext = React.createContext(({
  editedMultiplex: {} as Multiplex,
  isLoading: false,
  pendingDashboardIsLoading: false,
  resetContext: () => {},
  dashboardsList: [] as MultiplexDashboard[],
  dashboardsListSetter: (value: MultiplexDashboard[]) => {},
  pendingDashboard: {} as MultiplexDashboard,
  pendingDashboardSetter: (project: MultiplexDashboard) => {},
  pendingDashboardName: "",
  pendingDashboardNameInputHandler: (value: string) => {},
  pendingDashboardLogoUrl: "",
  pendingDashboardLogoUrlSetter: (value: string) => {},
  pendingDashboardPendingLogoFile: new File([], ""),
  pendingDashboardPendingLogoFileInputHandler: (file: File) => {},
  pendingDashboardProjects: [] as DashboardProject[],
  pendingDashboardProjectsHandler: (value: DashboardProject[]) => {},
  removedDashboardProjects: [] as DashboardProject[],
  removedDashboardProjectsHandler: (value: DashboardProject[]) => {},
  pendingDashboardGoal: 0,
  pendingDashboardGoalHandler: (value: number) => {},
  pendingDashboardPosition: -1,
  pendingDashboardPositionSetter: (value: number) => {},
  submitDashboardWrite: (mode: boolean, position: number) => {},
  triggerListMode: false,
  deleteDashboard: (project: MultiplexDashboard) => {},
  submitUpdate: () => {}
}))

export const EditMultiplexDashboardsContextProvider: React.FC = (props) => {
  const [editedMultiplex, setEditedMultiplex] = useState({} as Multiplex);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingDashboardIsLoading, setPendingDashboardIsLoading] = useState(false);
  const [dashboardsList, setDashboardsList] = useState([] as MultiplexDashboard[])
  const [pendingDashboard, setPendingDashboard] = useState({} as MultiplexDashboard);
  const [pendingDashboardName, setPendingDashboardName] = useState("");
  const [pendingDashboardPendingLogoFile, setPendingDashboardPendingLogoFile] =
    useState<File>(new File([], ""));
  const [pendingDashboardLogoUrl, setPendingDashboardLogoUrl] = useState("");
  const [pendingDashboardProjects, setPendingDashboardProjects] = useState([] as DashboardProject[])
  const [removedDashboardProjects, setRemovedDashboardProjects] = useState<DashboardProject[]>([])
  const [pendingDashboardGoal, setPendingDashboardGoal] = useState(0)
  const [pendingDashboardPosition, setPendingDashboardPosition] = useState(-1);
  const [triggerListMode, setTriggerListMode] = useState(false);
  const appCtx = useContext(AppContext);

  useEffect(() => {
    if (appCtx.currentMultiplex.id) {
      setEditedMultiplex(appCtx.currentMultiplex);
      setIsLoading(false);
    }
  }, [appCtx.currentMultiplex]);

  const resetContext = () => {
    setEditedMultiplex({} as Multiplex)
    setIsLoading(false)
    setPendingDashboardIsLoading(false)
    setDashboardsList([])
    setPendingDashboard({} as MultiplexDashboard)
    setPendingDashboardName("")
    setPendingDashboardPendingLogoFile(new File([], ""))
    setPendingDashboardLogoUrl("")
    setRemovedDashboardProjects([])
    setRemovedDashboardProjects([])
    setPendingDashboardGoal(0)
    setPendingDashboardPosition(-1)
  }

  const submitDashboardWrite = async(editMode: boolean, position: number) => {
    setPendingDashboardIsLoading(true)
    const writeDashboardId = pendingDashboard.id ? pendingDashboard.id : uuidv4();
    pendingDashboardProjects.map(project => {
      project.dashboardId = writeDashboardId
      return project
    })
    API.writeMultiplexDashboard(
      editedMultiplex.id,
      writeDashboardId,
      pendingDashboardName,
      pendingDashboardPendingLogoFile,
      pendingDashboardLogoUrl,
      pendingDashboardProjects,
      pendingDashboardGoal,
      position,
      editMode
    )
      .then((status) => {
        setPendingDashboardIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Création du compteur réussie."
        });
        setTriggerListMode(!triggerListMode);
      })
      .catch((e) => {
        setPendingDashboardIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });

    removedDashboardProjects.map((project) => {
      return API.unbindDashboardtoProject(project.parentShowId, project.id)
    })
    pendingDashboardProjects.map((project) => {
      return API.bindDashboardtoProject(project.parentShowId, project.id, pendingDashboard.id)
    })
  }

  const deleteDashboard = (dashboard: MultiplexDashboard) => {
    API.deleteMultiplexDashboard(
      editedMultiplex.id,
      dashboard.id,
    )
      .then((status) => {
        setPendingDashboardIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Compteur supprimé."
        });
        setTriggerListMode(!triggerListMode);
      })
      .catch((e) => {
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  }

  const dashboardsListSetter = (value: MultiplexDashboard[]) => {
    setDashboardsList(value);
  };

  const pendingDashboardSetter = (value: MultiplexDashboard) => {
    setPendingDashboard(value);
  };

  const pendingDashboardNameInputHandler = (value: string) => {
    setPendingDashboardName(value);
  };

  const pendingDashboardPendingLogoFileInputHandler = (file: File) => {
    setPendingDashboardPendingLogoFile(file);
  };

  const pendingDashboardLogoUrlSetter = (value: string) => {
    setPendingDashboardLogoUrl(value);
  };

  const pendingDashboardProjectsHandler = (value: DashboardProject[]) => {
    setPendingDashboardProjects(value);
  };

  const removedDashboardProjectsHandler = (value: DashboardProject[]) => {
    setRemovedDashboardProjects(value)
  }

  const pendingDashboardGoalHandler = (value: number) => {
    setPendingDashboardGoal(value)
  }

  const pendingDashboardPositionSetter = (value: number) => {
    setPendingDashboardPosition(value);
  };

  const submitUpdate = () => {
    setIsLoading(true);
    const correctedPositionsDashboardsList: MultiplexDashboard[] = [];
    [...dashboardsList]
      .sort((a, b) => (a.position > b.position ? 1 : -1))
      .map((dashboard, index) => {
        const dashboardCorrected = dashboard;
        dashboardCorrected.position = index;
        return correctedPositionsDashboardsList.push(dashboardCorrected);
      });
    API.updateDashboardsConfig(editedMultiplex.id, correctedPositionsDashboardsList)
      .then((status) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "success",
          content: "Mise à jour des projets réussie."
        });
      })
      .catch((e) => {
        setIsLoading(false);
        appCtx.toggleNotification({
          type: "error",
          content: "Une erreur est survenue."
        });
      });
  };

  return (
    <EditMultiplexDashboardsContext.Provider
      value={{
        editedMultiplex,
        isLoading,
        pendingDashboardIsLoading,
        resetContext,
        dashboardsList,
        dashboardsListSetter,
        pendingDashboard,
        pendingDashboardSetter,
        pendingDashboardName,
        pendingDashboardNameInputHandler,
        pendingDashboardLogoUrl,
        pendingDashboardLogoUrlSetter,
        pendingDashboardPendingLogoFile,
        pendingDashboardPendingLogoFileInputHandler,
        pendingDashboardProjects,
        pendingDashboardProjectsHandler,
        removedDashboardProjects,
        removedDashboardProjectsHandler,
        pendingDashboardGoal,
        pendingDashboardGoalHandler,
        pendingDashboardPosition,
        pendingDashboardPositionSetter,
        submitDashboardWrite,
        triggerListMode,
        deleteDashboard,
        submitUpdate
      }}
    >
      {props.children}
    </EditMultiplexDashboardsContext.Provider>
  )
}

export default EditMultiplexDashboardsContext;